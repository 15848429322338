import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
  },
  {
    path: '/seller',
    name: 'SellerLayout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "sellerLayout" */ '../layouts/SellerLayout.vue'
      ),
    children: [
      {
        path: '/',
        name: 'verkauf',
        component: () =>
          import(/* webpackChunkName: "sellerPage" */ '../views/Seller.vue'),
        meta: {
          roles: ['isSellerUser']
        }
      },
      {
        path: '/buyer',
        name: 'einkauf',
        component: () =>
          import(/* webpackChunkName: "sellerPage" */ '../views/Buyer.vue'),
        meta: {
          roles: ['isPurchaserUser']
        }
      },
      {
        path: '/seller/models',
        name: 'sellerModels',
        component: () =>
          import(/* webpackChunkName: "sellerModel" */ '../views/Models.vue'),
        meta: {
          roles: ['isSellerUser']
        }
      },
      {
        path: '/buyer/models',
        name: 'buyerModels',
        component: () =>
          import(/* webpackChunkName: "sellerModel" */ '../views/Models.vue'),
        meta: {
          roles: ['isPurchaserUser']
        }
      },
      {
        path: '/buyer/files',
        name: 'buyerFiles',
        component: () =>
          import(/* webpackChunkName: "buyerFiles" */ '../views/Files.vue'),
        meta: {
          roles: ['isPurchaserUser']
        }
      }
    ]
  },
  {
    path: '/search',
    name: 'searchPage',
    component: () =>
      import(/* webpackChunkName: "searchPage" */ '../views/SearchPage.vue')
  },
  {
    path: '/news',
    name: 'fullLayout',
    meta: { roles: ['isNewsUser'] },
    component: () =>
      import(
        /* webpackChunkName: "systemLayout" */ '../layouts/FullLayout.vue'
      ),
    children: [
      {
        path: '/access-denied',
        name: 'denied',
        component: () =>
          import(/* webpackChunkName: "system" */ '../views/AccessDenied.vue'),
        meta: { roles: ['all'] }
      },
      {
        path: '/',
        name: 'news',
        component: () =>
          import(/* webpackChunkName: "system" */ '../views/News.vue'),
        meta: { roles: ['isNewsUser'] }
      },
      {
        path: '/system',
        name: 'system',
        component: () =>
          import(/* webpackChunkName: "system" */ '../views/System.vue'),
        meta: {
          roles: ['isAppAdminUser', 'isUserManagementUser', 'isCalculationUser']
        }
      },
      {
        path: '/pdf-view',
        name: 'pdfView',
        component: () =>
          import(/* webpackChunkName: "system" */ '../views/PdfView.vue'),
        meta: {
          roles: ['seller', 'isUserManagementUser', 'isCalculationUser']
        }
      },
      {
        path: '/rabatte',
        name: 'rabatte',
        component: () =>
          import(/* webpackChunkName: "system" */ '../views/Rabatte.vue'),
        meta: { roles: ['seller', 'firmenAdmin', 'vmeAdmin'] }
      }
    ]
  },
  {
    path: '/seller/catalog-detail',
    name: 'CatalogDetailModal',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "sellerLayout" */ '../layouts/CatalogDetailLayout.vue'
      ),
    children: [
      {
        path: '/',
        component: () =>
          import(
            /* webpackChunkName: "sellerPage" */ '../views/ModelDetail.vue'
          ),
        meta: { roles: ['isSellerUser'] }
      },
      {
        path: '/buyer/model/:id',
        name: 'buyerCatalogDetail',
        component: () =>
          import(
            /* webpackChunkName: "sellerModel" */ '../views/ModelDetail.vue'
          ),
        meta: { roles: ['isPurchaserUser'] }
      },
      {
        path: '/seller/model/:id',
        name: 'sellerCatalogDetail',
        component: () =>
          import(
            /* webpackChunkName: "sellerModel" */ '../views/ModelDetail.vue'
          ),
        meta: { roles: ['isSellerUser'] }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
