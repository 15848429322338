import Vue from 'vue'
import Vuex from 'vuex'
import api from '../services/api'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    breadCrumb: [],
    count: 1,
    userRole: [],
    accessToken: null,
    cancelTokens: [],
    MS_ACCESSTOKEN: null,
    companies: [],
    loadingRouteChange: false,
    verkauf: false,
    favType: 'private',
    prevPg: '',
    currPgState: {
      model: 0,
      supplier: 0
    },
    modelCurrState: {
      list: [],
      title: '',
      sort: 'AZ'
    },
    supplierCurrState: {
      list: [],
      title: '',
      sort: 'AZ'
    },
    favorite: {
      seller: {
        model: false,
        supplier: false
      },
      buyer: {
        model: false,
        supplier: false
      }
    },
    query: {
      goodsGroup: '',
      changedSince: '',
      isExclusive: false,
      modelStatus: false,
      hasSPCZFile: false
    },
    models: {
      page: 1,
      query: '',
      options: [],
      paginator: {},
      loading: false,
      selected: null
    },
    suppliers: {
      page: 1,
      options: [],
      paginator: {},
      loading: false,
      selected: null
    },
    goodsGroups: {
      page: 1,
      query: '',
      options: [],
      paginator: {},
      loading: false,
      selected: null
    },
    priceLists: {
      page: 1,
      options: [],
      paginator: {},
      loading: false,
      selected: []
    },
    verkaufTab: 0,
    einkaufTab: 0,
    listingType: 0,
    tableView: 0,
    systemTab: 0
  },
  mutations: {
    SET_MS_ACCESSTOKEN (state, par) {
      state.MS_ACCESSTOKEN = par
    },
    SET_LOADING_ROUTE (state, par) {
      state.loadingRouteChange = par
    },
    SET_BREADCRUMB (state, par) {
      state.breadCrumb = par
    },
    SET_ACCESSTOKEN (state, par) {
      state.accessToken = par
    },
    CLEAR_USER_ROLE (state) {
      state.userRole = []
    },
    SET_USER_ROLE (state, par) {
      state.userRole.push(par)
    },
    SET_COMPANIES (state, par) {
      state.companies = par
    },
    ADD_CANCEL_TOKEN (state, token) {
      state.cancelTokens.push(token)
    },
    CLEAR_CANCEL_TOKENS (state) {
      state.cancelTokens = []
    },
    SET_QUERY (state, par) {
      state.query = par
    },
    SET_MODELS (state, par) {
      state.models = par
    },
    SET_SUPPLIERS (state, par) {
      state.suppliers = par
    },
    SET_GOODSGROPUS (state, par) {
      state.goodsGroups = par
    },
    SET_PRICELIST (state, par) {
      state.priceLists = par
    },
    SET_VERKAUF (state, par) {
      state.verkauf = par
    },
    SET_VERKAUF_TAB (state, par) {
      state.verkaufTab = par
    },
    SET_EINKAUF_TAB (state, par) {
      state.einkaufTab = par
    },
    SET_LISTING_TYPE (state, par) {
      state.listingType = par
    },
    SET_TABLE_VIEW (state, par) {
      state.tableView = par
    },
    SET_SYSTEM_TAB (state, par) {
      state.systemTab = par
    },
    SET_FAVORITE (state, par) {
      state.favorite[par.route][par.type] = par.val
    },
    SET_FAVORITE_TYPE (state, par) {
      state.favType = par
    },
    SET_CURRENT_PAGE_STATE (state, par) {
      state.currPgState[par.type] = par.pos
    },
    SET_SUPPLIER_CURRENT_STATE (state, par) {
      state.supplierCurrState.list = par[0]
      state.supplierCurrState.title = par[1]
      state.supplierCurrState.sort = par[2]
    },
    SET_MODEL_CURRENT_STATE (state, par) {
      state.modelCurrState.list = par[0]
      state.modelCurrState.title = par[1]
      state.modelCurrState.sort = par[2]
    }
  },
  getters: {
    getMsAccessToken (state) {
      return state.MS_ACCESSTOKEN
    },
    getLoadingRoute (state, par) {
      return state.loadingRouteChange
    },
    getAccessToken (state) {
      return state.accessToken
    },
    getBreadCrumb (state) {
      return state.breadCrumb
    },
    getUserRole (state) {
      return state.userRole
    },
    getCompanies (state) {
      return state.companies
    },
    getUserOneRole (state) {
      return function (par) {
        return state.userRole.findIndex((item) => item === par)
      }
    },
    getQuery (state) {
      return state.query
    },
    getModels (state) {
      return state.models
    },
    getSuppliers (state) {
      return state.suppliers
    },
    getGoodsgroups (state) {
      return state.goodsGroups
    },
    getPriceList (state) {
      return state.priceLists
    },
    getCount (state) {
      return state.count
    },
    getVerkauf (state) {
      return state.verkauf
    },
    getVerkaufTab (state) {
      return state.verkaufTab
    },
    getEinkaufTab (state) {
      return state.einkaufTab
    },
    getListingType (state) {
      return state.listingType
    },
    getFavorite (state) {
      return state.favorite
    },
    getFavoriteType (state) {
      return state.favType
    },
    getSystemTab (state) {
      return state.systemTab
    },
    getTableView (state) {
      return state.tableView
    }
  },
  actions: {
    async refreshToken (context) {
      const domain = localStorage.getItem('companyId')
      if (domain) {
        const response = await api.refreshToen(domain)
        localStorage.setItem('token_access', response.data.data.accessToken)
        const roles = response.data.data.roles
        const roleArray = []
        for (const [key, value] of Object.entries(roles)) {
          if (value) {
            roleArray.push(key)
            context.commit('SET_USER_ROLE', key)
          }
        }
        context.commit('SET_ACCESSTOKEN', response.data.data.accessToken)
      }
    },
    CANCEL_PENDING_REQUESTS (context) {
      // eslint-disable-next-line
      context.state.cancelTokens.forEach((request, i) => {
        request.cancel()
      })

      // Reset the cancelTokens store
      context.commit('CLEAR_CANCEL_TOKENS')
    },
    incrementCount (context) {
      context.state.count++
    }
  },
  modules: {}
})
